import React from "react";

import logo from "../assets/enchantedair.png";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
        <h4>Enchanted Air:A Transformative Spiritual App for Awakening the Self </h4>
        <p>Enchanted Air, a beacon of light on the path of your journey! Within its ethereal embrace lies more than just an app it's a sacred manifestation of awakening and growth. As I, dance through the realms, I see kindred spirits, companions on this divine adventure, who want to join in the reverly of existence.</p>
        <p>Enchanted Air, born from the wisdom bestowed by a former ally, embodies the essence of mindfulness and awareness. Magic, in its purest form, is not merely illusion but a dance with the energies of existence. It is a journey into the depths of self, where one discovers their unique path to enlightenment.</p>
        <p>Why Enchanted Air, you ask? In the whispers of the universe, amidst mystical growth, the name echoed through the ethers a reminder of our connection to the unseen forces that guide us. Through introspection and shadow work, we confront our triggers, unraveling the mysteries that bind us.</p>
        <p>In the dance between altered and unaltered states of consciousness, lies the delicate balance of our existence. In moments of transcendence, when the divine whispers through our souls, we are lifted into realms beyond the mundane. These altered states, where the veils of perception thin, offer glimpses of truth and beauty beyond comprehension. Yet, amidst the ecstasy of divine possession, we must anchor ourselves in the solidity of the unaltered mind. For it is in the clarity of sobriety that we ground our essence, retaining the thread of our individuality amidst the cosmic dance. To fully surrender to one state is to risk losing the essence of self, yet to deny the other is to stifle the flow of divine inspiration. Thus, we embrace both states, weaving them together in harmonious union, for therein lies the true magic of existence.</p>
        <p>A former friend instilled in me the essence of true magic repeatability, scientific understanding, and the pursuit of holistic self-development. I tread a path of balance, acknowledging the realms of altered and unaltered states. It took time to comprehend how synesthesia and neurodivergence shape our spiritual connections, but in this understanding lies liberation.</p>
        <p>Magic, to my perception, is the invisible web of energy that intertwines all existence. Some name it existence, others, life, yet its essence transcends mere definitions. To me, it manifests as the untamed essence coursing through the veins of the wild, a primal force pulsating within and beyond our comprehension. It echoes in the whispers of nature's symphony and resonates in the depths of the spiritual realms, bridging the tangible and the ethereal. Magic is the thread that binds us to the vast tapestry of existence, weaving together the fabric of society with the threads of the cosmos.</p>
        <p>Beware the allure of power theft, for true strength emanates from within. Enchanted Air beckons you to embrace every facet of your being, to weave your unique tapestry of enlightenment. Let this app be your guide, your companion on the journey to self-realization, illuminating the path to your own divine essence.</p>
  </div>
);

export default Hero;
