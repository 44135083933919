import React, { Fragment } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import NewsFeed from "../components/NewsFeed";

const Home = () => (
  <Fragment>
    <Hero />
    <hr />
    <NewsFeed/>
  </Fragment>
);

export default Home;
