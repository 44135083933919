import React, { Component, useState } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from './Loading';


class NewsFeed extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contentData: null
        };
    }

    componentDidMount() {
       

        // Simple GET request using fetch
        fetch('https://api.enchantedair.app/api/v1/news')
            .then(response => response.json())
            .then(data => this.setState({ contentData: data }));
    }

    render() {
        const contentData = this.state.contentData;
        if (contentData) {
            return (

                <div className="next-steps my-5">
                    <h2 className="my-5 text-center"></h2>
                    <Row className="d-flex justify-content-between">
                        {contentData.map((col, i) => (
                            <Col key={i} md={5} className="mb-4">
                                <h6 className="mb-3">
                                    <a href={col.link}>
                                        <FontAwesomeIcon icon="link" className="mr-2" />
                                        {col.title}
                                    </a>
                                </h6>
                                <p>{col.summary}</p>
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        };
        return (<Loading />);
    }
}

export default NewsFeed;

